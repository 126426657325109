import * as React from 'react';
import {GetServerSideProps} from 'next';
import {PageMeComp, ssrMe} from '@graphql/generated/page';
import LoginForm from '@components/pages/login/login-form';

export const getServerSideProps: GetServerSideProps = async ctx => {
  const {props} = await ssrMe.getServerPage({}, ctx);

  if (props.data.me) {
    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    };
  }

  return {props};
};

const Login: PageMeComp = _props => {
  return <LoginForm />;
};

export default Login;
